import { barcodecheck } from "@/functions"

export default {
  methods: {
    getTag(holder, tagtype, multiple = false) {
      if (!holder.Tags) 
        return multiple ? [] : null

      if (!multiple) {
        let out = ''
        holder.Tags.forEach(tag => {
          if (tag.Type == tagtype)
            out = tag.Value
        })
        return out
      } else {
        let out = []
        holder.Tags.forEach(tag => {
          if (tag.Type == tagtype) {
            out.push(tag.Value)
          }
        })
        return out
      }
    },
    setTag (holder, tagtype, val) {
      console.log('Setting tag', tagtype, val)
      let newTags = []
          
      if (val) {
        if (!Array.isArray(val)) {
          newTags.push({
            Type: tagtype,
            Value: val
          });
        } else {
          newTags = newTags.concat(val.map(v => { return { Type: tagtype, Value: v } }))
        }
      }

      if (holder.Tags) {
        holder.Tags.forEach(tag => {
          // Only re-add tags other than current tag
          if (tag.Type != tagtype)
            newTags.push(tag)
        })
      }

      this.$set(holder, 'Tags', newTags)
    },
    getAttribute(holder, attrtype) {
      if (!holder.Attributes) return null

      let out = ''
      holder.Attributes.forEach(attr => {
        if (attr.Type == attrtype)
          out = attr.Value
      })
      return out
    },
    setAttribute (holder, attrtype, val) {
      let newAttrs = []
      if (val) {
        newAttrs.push({
          Type: attrtype,
          Value: val
        });
      }

      if (holder.Attributes) {
        holder.Attributes.forEach(attr => {
          // Only re-add attributes other than current attribute
          if (attr.Type != attrtype)
            newAttrs.push(attr)
        })
      }

      this.$set(holder, 'Attributes', newAttrs)
    },
    validateOtherInfluences(holder, holderErrors) {
      let errors = []
      
      let tooLong = 0
      let tooMany = 0
      
      this.getTag(holder, 'OtherInfluences', true).forEach(i => {
        if (i.length > 64) tooLong++
        if (i.split(/[, &/-]+/).length > 3) tooMany++
      })

      if (tooMany > 0)
        errors.push('Maximaal 3 woorden per regel')

      if (tooLong > 0)
        errors.push('Maximaal 64 karakters per regel')

      if (errors.length > 0) {
        holderErrors['Tags.OtherInfluences'] = errors
      }
    },
    validateContributors(holder, holderErrors) {
      let errors = []
      if (!holder.Contributors || holder.Contributors.length == 0) {
        errors.push('Het is verplicht om Contributors op te geven.')
      }

      if (!holder.Contributors || holder.Contributors.every(c => c.Role != 'Recording Artist')) {
        errors.push('Een uitvoerende artiest is verplicht')
      }

      if (errors.length > 0) {
        holderErrors.Contributors = errors
      }
    },
    validate(model, defs, field, holderErrors) {
      let errors = []
      let def = defs[field]
      if (!def) console.log('Unable to find field', field)
      let value = ''
      
      if (['Album', 'Track'].includes(def.JsonSection)) {
         value = model[field]
      }

      if (def.JsonSection == 'Tags') {
        value = this.getTag(model, def.JsonField)
      }

      if (def.JsonSection == 'Attributes') {
        value = this.getAttribute(model, def.JsonField)
      }

      if (typeof value === 'string' || value instanceof String) {
        value = value.trim()
      }

      if (def.Mandatory && !value) {
        errors.push(def.Title + ' is een verplicht veld')
      }

      if (value) {
        if (field == 'PLine' || field == 'CLine') {
          if (!value.match(/^[0-9]{4} [^ ]{1}.*/)) {
            errors.push(this.$root.$data.pageSnippets['validate-p-c-line'])
          }
        }

        if (field == 'Attributes.PreviewDuration' || field == 'Attributes.PreviewStartSeconds') {
          if (!value.match(/^[0-9]+$/)) {
            errors.push('Dit veld mag alleen bestaan uit een getal (seconden)')
          }
        }

        if (field == 'Attributes.CoverArtPreviewDays' || field == 'Attributes.TrackListingPreviewDays' || field == 'Attributes.PreOrderDays') {
          if (!value.match(/^[0-9]+$/)) {
            errors.push('Dit veld mag alleen bestaan uit een getal (dagen)')
          } else if (value < 1) {
            errors.push('Dit veld moet een getal hoger dan 0 bevatten.')
          }
        }

        if (['VolumeNr', 'MovementNr', 'BPM'].includes(field)) {
          if (!value.match(/^[0-9]+$/)) {
            errors.push('Dit veld mag alleen bestaan uit een getal')
          }
        }

        if (field == 'CatalogNr' && !value.match(/^[0-9a-zA-Z_]+$/)) {
          errors.push('Catalogus nummer mag alleen leters, cijfers en underscores bevatten.')
        }

        if (field == 'UPCorEAN' && !barcodecheck(value)) {
          errors.push('Ongeldige barcode')
        }

        if (field == 'ISRC') {
          let isrc = value.replace(/[^a-z0-9]/gi, '').toUpperCase()
          if (isrc.startsWith('XX') || isrc.length != 12)
            errors.push('Ongeldige ISRC, deze moet bestaan uit 12 karakters, beginnend met een geldige prefix.')
        }

        if (field == 'Title' || field == 'Attribution') {
          if (value.indexOf('.wav') != -1)
            errors.push('Dit veld mag geen .wav bevatten.')
          
          let forbidden = ['ft', 'feat', 'produced', 'featuring']
          let hasForbidden = value.toLowerCase().split(/[ ./]/).some(word => { return forbidden.includes(word) })

          if (hasForbidden || value.includes('prod by'))
            errors.push(this.$root.$data.pageSnippets['validate-title'])
        }
      }

      if (errors.length > 0)
        holderErrors[field] = errors
    }
  }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fieldDefs != null
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("h1", [
                      _vm._v("Playlist concept"),
                      _vm.release != null
                        ? _c("span", [
                            _vm._v(
                              ": " +
                                _vm._s(_vm.release.Title) +
                                " - " +
                                _vm._s(_vm.release.Attribution)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "a",
                      {
                        staticClass: "knowledgebase",
                        attrs: {
                          href: "http://support.boep.nl/kb/root.aspx",
                          target: "_blank"
                        }
                      },
                      [_vm._v("Raadpleeg kennisbank op ons supportportal")]
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _vm.$root.$data.me.AllowManageReleaseConcepts
                      ? _c(
                          "ul",
                          { staticClass: "wizard-steps" },
                          _vm._l(_vm.steps, function(item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                class: { active: _vm.step == index },
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.setStep(index)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    !_vm.$root.$data.me.AllowManageReleaseConcepts
                      ? _c(
                          "ul",
                          { staticClass: "wizard-steps" },
                          _vm._l(_vm.steps, function(item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                class: { active: _vm.step == index }
                              },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.release != null
            ? _c(_vm.currentStepComponent, {
                tag: "component",
                attrs: {
                  release: _vm.release,
                  fieldDefs: _vm.fieldDefs,
                  createdRelease: _vm.createdRelease,
                  validationErrors: _vm.validationErrors
                },
                on: { proceed: _vm.onProceed, create: _vm.onCreate }
              })
            : _vm._e(),
          _vm.creating
            ? _c("div", [_vm._v("Release aanmaken"), _c("b-spinner")], 1)
            : _vm._e(),
          _vm.$root.$data.me.AllowManageReleaseConcepts
            ? _c(
                "b-container",
                { staticClass: "mt-5", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("pre", [
                          _vm._v(_vm._s(_vm._f("jsonPretty")(_vm.release)))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("b-row", [_c("b-col")], 1)
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }